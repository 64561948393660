import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import { Box } from "@mui/material";
import { info } from "../../info/Info";



export default function About() {
    const firstName = info.firstName

    function aboutMeText() {
        return <>

            <Box textAlign={'center'} style={{fontSize: "2.5rem", fontWeight: "bold", padding:'2%', paddingBottom:'0%' }}>
            <span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}> About {firstName} </span>
            
            </Box>
            <Box textAlign={'justify'} padding={"2%"} color={'black'} fontSize={ "1.5rem"} style={{ color: 'white'}}>

                {info.bio}
            </Box>

            <Box textAlign={'center'}  padding={"2%"} paddingTop={"0%"} color={'black'} fontSize={ "1.5rem"} style={{ color: 'white'}}>

                {info.bio2}
            </Box>
        </>;
    }

    function skillsText() {
        return <>
  <Box textAlign={'center'} style={{fontSize: "2rem", fontWeight: "bold", padding:'2%', paddingBottom:'0%' }}>
<span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>Qualifications</span>
                         
            </Box>
            <Box padding={"3%"} >
                <p style={{ color: "#ffffff", fontWeight: "bold" }}> Proficient With:</p>
                <ul className={Style.skills} >
                    {info.skills.proficientWith.map((proficiency, index) => <li key={index} style={{ color: 'white' }}><Box display={"flex"}> <Box width={25} /> ❖ {proficiency}</Box></li>)}
                </ul>
                <p style={{ color: "#ffffff", fontWeight: "bold" }}> Exposed To:</p>
                <ul className={Style.skills}>
                    {info.skills.exposedTo.map((skill, index) => <li key={index} style={{ color: 'white' }}><Box display={"flex"}> <Box width={25} /> ❖ {skill}</Box></li>)}
                </ul>
            </Box>
        </>;
    }

    function miscText() {
        return <>
<Box textAlign={'center'} style={{fontSize: "2rem", fontWeight: "bold", padding:'2%', paddingBottom:'0%' }}>
<span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>Hobbies/Interests</span>
                
                </Box>
            <Box padding={"3%"}>
                <ul className={Style.skills}>
                    {info.hobbies.map((hobby, index) =>
                        <li key={index} style={{ color: "white" }}>
                            <Box component={'span'} mr={'1rem'} left={50} alignContent={"stretch"} display="flex">
                                <Box width={50} height={75}></Box>
                                <img src={hobby.icon} height={"50rem"} />
                                <Box width={25}></Box>
                                <box style={{ flex: 1, justifyContent: 'center', alignItems: "center", lineHeight: "50px" }}>
                                    {hobby.label}
                                </box>
                            </Box>
                        </li>
                    )}
                </ul>
            </Box>
        </>;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <Terminal text={aboutMeText()} />
            <Terminal text={skillsText()} />
            <Terminal text={miscText()} />
        </Box>
    )
}